<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import "echarts-gl";
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      data: {
        type: Number,
        default: 80
      },
      color:{
        type: String,
        default: '#1ADCFF' 
      },
      units:{
        type:String
      },
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'data':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
        this.chart.setOption({
            title: [
            {
                text: `${this.data}%`,
                show: true,
                x: "center",
                y: "26%",
                textStyle: {
                    fontSize: 12,
                    color: "#FFFFFF",
                },
            },
            {
                text: '占比',
                x: "center",
                y: "45%",
                borderColor: "#fff",
                textStyle: {
                    fontSize: 12,
                    color: "#fff",
                },
            },
        ],
        polar: {
            center: ["50%", "50%"],
            radius: ["70%", "90%"],
        },
        angleAxis: {
            max: 100,
            show: false,
        },
        radiusAxis: {
            type: "category",
            show: true,
            axisLabel: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        series: [
            {
                data: [this.data],
                name: "",
                itemStyle:{
                    color:this.color
                },
                type: "bar",
                roundCap: true,
                showBackground: true,
                coordinateSystem: "polar",
            },
        ],
        })
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>